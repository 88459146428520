import FileUploadController from "./file_upload";

export default class extends FileUploadController {
  static targets = [...FileUploadController.targets];

  connect() {
    super.connect();
  }

  populateFileItem(files) {
    this.emptyFileList();

    Array.from(files).forEach((file) => {
      const clone = this.templateTarget.cloneNode(true);

      clone.classList.remove("file-item-template");
      clone.classList.add("file-item");
      clone.style.display = "flex";

      clone.querySelector(".file-name").textContent =
        file.name.length > 100 ? file.name.substring(0, 97) + "..." : file.name;
      clone.querySelector(".file-size").textContent = `${(file.size / 1024).toFixed(2)} KB`;

      const reader = new FileReader();
      reader.onload = (e) => {
        clone.querySelector(".file-image").src = e.target.result;
      };
      reader.readAsDataURL(file);

      this.listTarget.appendChild(clone);
    });
  }
}
