import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "hiddenInput"];

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const openModal = urlParams.get("open_condolence_modal");

    if (openModal === "true") {
      this.initializeModal();
    }
  }

  initializeModal() {
    const interval = 2000;
    const maxAttempts = 20;

    let attempts = 0;

    const checkOverlayInstance = () => {
      if (!window.$hsOverlayCollection) {
        window.$hsOverlayCollection = [];
      }

      const modalInstance = window.HSOverlay.getInstance("#modal-condolence");

      if (modalInstance) {
        window.HSOverlay.open("#modal-condolence");

        const url = new URL(window.location.href);
        url.searchParams.delete("open_condolence_modal");
        window.history.replaceState({}, document.title, url);
      } else if (attempts < maxAttempts) {
        attempts++;
        setTimeout(checkOverlayInstance, interval);
      } else {
        console.error("No overlay after 20 attempts.");
      }
    };

    checkOverlayInstance();
  }

  async submit(event) {
    event.preventDefault();

    this.hiddenInputTarget.value = event.target.innerText == "Hide" ? "true" : "false";
    const formData = new FormData(this.formTarget);
    formData.append("_method", "PATCH");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
    const response = await fetch(this.formTarget.action, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      body: formData,
    });

    if (response.ok) {
      this.element.classList.toggle("message-hidden");
    }
  }
}
